export default {
	data: () => ({
		langRo: {
			title: 'Cereri',
			list: 'Listă',
			calendar: 'Calendar',
			confirmation: 'Autoconfirmare',
			table: {
				title: 'Cereri confirmate',
				name: 'NUMELE',
				phone: 'NUMĂR TELEFON',
				data: 'DATA ÎNSCRIERII',
				endData: 'EFECTUAREA ÎNSCRIERII'
			},
			btn: {
				title: 'Confirmă',
				done: 'Anulează',
				addUser: 'Adaugă înscriere'
			},
			emptyMessage: 'Nu aveti cereri noi',
			msgConfirmate: 'Dvs nu aveti cereri confirmate'
		}
	})
}








