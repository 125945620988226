export default {
	data: () => ({
		langEng: {
			title: 'Requests',
			list: 'List',
			calendar: 'Calendar',
			confirmation: 'Autoconfirmare',
			table: {
				title: 'Confirmed request',
				name: 'Name',
				phone: 'Phone number',
				data: 'Registration Date',
				endData: 'Registration confirmed'
			},
			btn: {
				title: 'Confirm',
				done: 'Сancel',
				addUser: 'Add signup'
			},
			emptyMessage: 'You have no new requests',
			msgConfirmate: 'You have no confirmed requests'
		}
	})
}








