export default {
	data: () => ({
		langRu: {
			title: 'Заявки',
			list: 'Список',
			calendar: 'Календарь',
			confirmation: 'Авто подтверждения',
			table: {
				title: 'Подтвержденные заявки',
				name: 'Имя',
				phone: 'Номер телефона',
				data: 'ДАТА РЕГИСТРАЦИИ',
				endData: 'ПОСТАНОВКА НА УЧЕТ'
			},
			btn: {
				title: 'Подтвердить',
				done: 'Отменить',
				addUser: 'Добавить'
			},
			emptyMessage: 'У вас нет новых заявок',
			msgConfirmate: 'У вас нет подтверждённых заявок'
		}
	})
}